import { useState, Fragment, useEffect } from "react";

// MaterialUI
import {
  Dialog,
  IconButton,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Alert,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";

// Composant
import MatriceTable from "../Matrix/MatriceTable";
import MatrixTemplateSelect from "../ListMatrix/MatrixTemplateSelect";

// Formik
import { useFormik } from "formik";

// Redux
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/index";
import { sessionEnd } from "../../redux/actions/index";

// Interface
import {
  IMatriceUnit,
  MatriceWrapperClientsData,
} from "../../interface/matrice";
import { IProject } from "../../interface/project";

// Icons
import MatrixEditButtonStack from "./MatrixEditButtonStack";
import { getOne } from "../../fetchs/get";

/** Dialog pour éditer une matrice (master ou non) et ses métadonnées (nom, description, clients) */
const MatrixDialogEdit = ({
  setOpenModal,
  matrice,
  pushModifiedMatrice,
  updateOneMatrix = () => { },
  isMaster = false,
  project = null,
}: {
  setOpenModal: Function;
  matrice: MatriceWrapperClientsData;
  pushModifiedMatrice: Function;
  updateOneMatrix?: Function;
  isMaster?: boolean;
  project?: IProject | null;
}) => {
  const [error, setError] = useState<string>("");
  const [matriceEdit, setMatrice] =
    useState<MatriceWrapperClientsData>(matrice);
  const [selectedMatriceID, setSelectedMatriceID] = useState<string>("");
  const [hasChanged, setHasChanged] = useState<boolean>(false);

  let dispatch = useDispatch();
  let token = useSelector((state: RootState) => {
    return state.user.token;
  });
  const setMatriceData = (data: Array<IMatriceUnit>) => {
    if (matrice)
      setMatrice({
        ...matriceEdit,
        data: data,
        clients: matrice.clients,
        creator: matrice.creator,
      });
    setHasChanged(true);
  };

  useEffect(() => {
    if (token && !isMaster) {
      getOne(token, "/matrice/id/", matrice._id, setMatrice, () => {
        dispatch(sessionEnd());
      });
    }
  }, []);

  const formik = useFormik({
    initialValues: matrice,
    onSubmit: (values) => {
      const newMatrice = {
        ...matriceEdit,
        name: values.name,
        desc: values.desc,
        clients: values.clients,
      };
      if (!isMaster) updateOneMatrix(newMatrice);
      pushModifiedMatrice(newMatrice);
      setOpenModal(false);
    },
  });

  // const author = useSelector((state: RootState) => {
  //   return state.user.username;
  // });

  // const isAuthor = author === (matrice.creator ?? author);

  // const textEdit = () => {
  //   if (isAuthor || isMaster) return "Vous pouvez modifier la matrice :";
  //   else return "Vous êtes en mode consultation aucune modification ne sera prise en compte :";
  // };

  return (
    <Fragment>
      <Dialog
        open={true}
        keepMounted={false}
        onClose={() => setOpenModal(false)}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth={true}
        scroll="paper"
        maxWidth="xl"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 10px",
          }}
          id="scroll-dialog-title"
        >
          <DialogTitle>Données de la matrice</DialogTitle>
          <IconButton
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <DialogContent
          dividers={true}
          id="scroll-dialog-description"
        >
          <form onSubmit={formik.handleSubmit}>
            {!isMaster && (
              <MatrixEditButtonStack
                // isAuthor={isAuthor}
                formik={formik}
                matrice={matriceEdit as MatriceWrapperClientsData}
                setMatrice={setMatrice}
                editClient={project ? false : true}
                hasChanged={hasChanged}
              />
            )}

            {matriceEdit && project && (
              <MatrixTemplateSelect
                selectedMatriceID={selectedMatriceID}
                setSelectedMatriceID={setSelectedMatriceID}
                setMatrice={setMatrice}
                matrice={matriceEdit}
                projects={project}
                setHasChanged={setHasChanged}
              ></MatrixTemplateSelect>
            )}

            <Typography component="p" sx={{ fontWeight: "bold", my: 2 }}>
              {/* {textEdit()} */}
            </Typography>

            <MatriceTable
              lotProps={null}
              matrice={matriceEdit.data}
              setMatrice={setMatriceData}
              setErrorParent={setError}
              selectedMatriceID={selectedMatriceID}
            />

            {error ? <Alert severity="error">{error}</Alert> : null}
          </form>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Button variant="outlined" onClick={() => setOpenModal(false)}>
            Annuler
          </Button>
          <Button
            variant="contained"
            type="submit"
            // disabled={isMaster === true ? false : error !== "" || !isAuthor}
            disabled={error !== ""}
            onClick={(e: any) => formik.handleSubmit(e)}
          >
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default MatrixDialogEdit;