// React
import { useState } from "react";

// MaterialUI
import { Button, Box } from "@mui/material";
import Alert from "@mui/material/Alert";

// Composant
import SyncDialog from "../Sync/SyncDialog";

// Interface
import { MatriceWrapperClientsData } from "../../interface/matrice";
import MatrixMetadataDialogEdit from "./MatrixMetadataDialogEdit";

// Icons
import SyncIcon from "@mui/icons-material/Sync";

// Tools
import useWindowDimensions from "../../tools/useWindowDimensions";

const MatrixEditButtonStack = ({
  // isAuthor,
  formik,
  matrice,
  setMatrice,
  editClient,
  hasChanged,
}: {
  // isAuthor: boolean;
  formik: any;
  matrice: MatriceWrapperClientsData;
  setMatrice: Function;
  editClient: boolean;
  hasChanged: boolean;
}) => {
  const [openMetadataModal, setOpenMetadataModal] = useState<boolean>(false);
  const [openSyncModal, setOpenSyncModal] = useState<boolean>(false);

  const [isSynchronized, setIsSynchronized] = useState(matrice);
  let button;

  const { width } = useWindowDimensions();
  let mobile: boolean = false;
  if (width) {
    mobile = width > 900 ? false : true;
  }

  if(isSynchronized){
    button = <Button
    variant="outlined"
    sx={{ m: 1, width: "fit-content" }}
    onClick={() => setOpenSyncModal(true)}
    startIcon={<SyncIcon />}
    disabled={hasChanged || matrice.changes.length === 0}
    >Synchroniser</Button>
  }else{
    button = <Button
    style={{backgroundColor: "red"}}
    variant="outlined"
    sx={{ m: 1, width: "fit-content" }}
    startIcon={<SyncIcon />}
    disabled={hasChanged || matrice.changes.length === 0}
  >Synchroniser</Button>
  }

  return (
    <Box>
      <Button variant="outlined" sx={{ width: "fit-content" }} /*disabled={!isAuthor}*/ onClick={() => setOpenMetadataModal(true)}>
        Éditer Métadonnées
      </Button>
      {!mobile && <Button
        variant="outlined"
        sx={{ m: 1, width: "fit-content" }}
        onClick={() => setOpenSyncModal(true)}
        startIcon={<SyncIcon />}
        disabled={/*!isAuthor ||*/ matrice.changes.length === 0}
      >
        Synchroniser
  </Button> }
      {openMetadataModal && (
        <MatrixMetadataDialogEdit setOpenModal={setOpenMetadataModal} formik={formik} editClient={editClient} />
      )}
      {openSyncModal && <SyncDialog setOpenModal={setOpenSyncModal} matrice={matrice} setMatrice={setMatrice} />}
      {matrice.changes.length === 0 && (
        <Alert severity="warning">
          Cette matrice a été créée lors d'une ancienne version de Kairos, merci d'effectuer un changement de valeur
          dans la matrice ci-dessous pour pouvoir accéder à l'outil de synchronisation.
        </Alert>
      )}
    </Box>
  );
};

export default MatrixEditButtonStack;
